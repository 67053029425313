import React, { FC, useEffect, useRef } from 'react';
import './modal.scss';

type ModalProps = {
  children: React.ReactNode | React.ReactNode[];
  isOpen: boolean;
  onClose: () => void;
  width?: number;
  height?: number;
  ignoreBackdrop?: boolean;
};

const Modal: FC<ModalProps> = ({ children, isOpen, onClose, width, height, ignoreBackdrop = false }: ModalProps) => {
  const modalContainer = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isOpen) {
      modalContainer.current?.focus();
    }
  }, [isOpen]);

  const offClick = (e: any) => {
    if (!ignoreBackdrop && (e.target.className === 'modal' || e.target.className === 'xs-modal')) {
      onClose();
    }
  };

  return (
    <>
      {isOpen && (
        <div data-testid="modal" className="modal" onMouseDown={offClick}>
          <div
            data-testid="desktop-modal-container"
            className="modal-content-container"
            ref={modalContainer}
            style={{ width: width, height: height }}
            tabIndex={0}>
            <div>{children}</div>
          </div>
        </div>
      )}
      <div data-testid="xs-modal" className={isOpen ? 'xs-modal' : 'xs-modal hide'} onMouseDown={offClick}>
        <div className="modal-content-container" ref={modalContainer} style={{ width: '100%', height: height }} tabIndex={0}>
          <div>{children}</div>
        </div>
      </div>
    </>
  );
};

export default Modal;
