import { FC, useState } from 'react';
import FilterDropdown, { FilterOption } from '../custom-input/FilterDropdown';
import ValidatedInput from '../custom-input/ValidatedInput';
import { SearchSVG } from '../svgs';
import { LABEL_CONSTANTS } from '../../constants/common';
import './searchBy.scss';

interface SearchByProps {
  searches: { criteria: FilterOption<any>; query: string }[];
  setSearches: (e: { criteria: FilterOption<any>; query: string }[]) => void;
  searchCriteriaOptions: FilterOption<any>[];
}

const SearchBy: FC<SearchByProps> = ({ searchCriteriaOptions, searches, setSearches }) => {
  const [selectedSearchCriteria, setSelectedSearchCriteria] = useState<FilterOption<any> | null>(null);
  const [query, setQuery] = useState<string>('');

  const handleSearchCriteriaChange = (newCriteria: FilterOption<any>) => {
    setSelectedSearchCriteria(newCriteria);
    setQuery('');
  };

  const handleAddOrUpdateQuery = () => {
    if (selectedSearchCriteria && query) {
      const existingIndex = searches.findIndex(item => item.criteria.name === selectedSearchCriteria.name);

      if (existingIndex !== -1) {
        const updatedSearch = [...searches];
        updatedSearch[existingIndex] = { criteria: selectedSearchCriteria, query };
        setSearches(updatedSearch);
      } else {
        setSearches([...searches, { criteria: selectedSearchCriteria, query }]);
      }

      setSelectedSearchCriteria(null);
      setQuery('');
    }
  };

  return (
    <>
      <div className="search-by">
        <p className="search-by-label">{LABEL_CONSTANTS.SEARCH_BY}:</p>

        <div className="input-stack">
          <FilterDropdown
            placeholder="Select"
            options={searchCriteriaOptions}
            value={{
              name: selectedSearchCriteria?.name ?? '',
              value: selectedSearchCriteria?.value ?? '',
            }}
            onChange={handleSearchCriteriaChange}
          />

          <ValidatedInput
            disabled={!selectedSearchCriteria}
            validatedStateForAutoFill={{ value: query ?? '', valid: true }}
            placeholder={''}
            label={''}
            setValidatedState={e => setQuery(e.value)}
            validate={false}
            validators={[]}
          />

          <div className="search-svg-wrapper">
            <input disabled type="text" className="icon-holder" />
            <button onClick={handleAddOrUpdateQuery} className="search-toggle-visibility button search-border">
              <SearchSVG />
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default SearchBy;
