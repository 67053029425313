import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import FilterDropdown, { FilterOption } from '../custom-input/FilterDropdown';
import ToggleSwitch from '../custom-input/ToggleSwitch';
import DateRangeInput from '../custom-input/DateInputRange';
import { AlertSVG, ClearFilterSVG, DownloadSVG, PlusCircleSvg } from '../svgs';
import { TRANSACTION_MANAGEMENT_PATHS } from '../../constants/routePaths';
import { LABEL_CONSTANTS, TRANSACTION_CONSTANTS, VALIDATION_ALERT_CONSTANTS } from '../../constants/common';
import { sortObjectsAlphabetically } from '../../utils/commonUtils';
import { TransactionResolutionEnum, TransactionStatusEnum, TransactionTypeEnum } from '../../types/enums/transaction.enum';
import './filterBy.scss';

interface FilterByProps {
  type: string;
  setType: (e: string) => void;
  status: string;
  setStatus: (e: string) => void;
  resolution: string;
  setResolution: (e: string) => void;
  dateRange: { startDate: Date | null; endDate: Date | null };
  setDateRange: (e: { startDate: Date | null; endDate: Date | null }) => void;
  showBulkTransactions?: boolean;
  onToggleShowBulkTransactions?: () => void;
  includeAdminFilters?: boolean;
  resetFilters?: () => void;
  onDownloadReportClick: () => void;
}

const FilterBy: React.FC<FilterByProps> = ({
  type,
  setType,
  status,
  setStatus,
  resolution,
  setResolution,
  dateRange,
  setDateRange,
  showBulkTransactions,
  onToggleShowBulkTransactions,
  includeAdminFilters = false,
  resetFilters,
  onDownloadReportClick,
}) => {
  const navigate = useNavigate();
  const [showHighlight, setShowHighlight] = useState(false);
  const isDateRangeSelected = dateRange.startDate && dateRange.endDate;

  let typeOptions: FilterOption<string>[] = [];
  let statusOptions: FilterOption<string>[] = [];
  let resolutionOptions: FilterOption<string>[] = [];

  for (const [key, value] of Object.entries(TransactionTypeEnum)) {
    typeOptions.push({ name: key.replace('Default', 'All'), value: value });
  }

  for (const [key, value] of Object.entries(TransactionStatusEnum)) {
    statusOptions.push({ name: key.replace('Default', 'All'), value: value });
  }

  for (const [key, value] of Object.entries(TransactionResolutionEnum)) {
    resolutionOptions.push({ name: key.replace('Default', 'All').replace('Pickup', 'Picked Up'), value: value });
  }

  const handleDownloadClick = () => {
    if (!isDateRangeSelected) {
      setShowHighlight(true);
    } else {
      onDownloadReportClick();
    }
  };

  const handleDateChange = (dateRange: { startDate: Date | null; endDate: Date | null }) => {
    setDateRange(dateRange);
    setShowHighlight(false);
  };

  return (
    <div className="filter-by">
      <div className="label">
        <div className="reset-filter-row">
          <p>{LABEL_CONSTANTS.FILTER_BY}:</p>
          {resetFilters && (
            <button type="button" className="reset-filters-button" onClick={() => resetFilters()}>
              <ClearFilterSVG />
              <u>{LABEL_CONSTANTS.RESET_FILTERS}</u>
            </button>
          )}
        </div>

        <div className="download-button-row">
          {!showBulkTransactions && (
            <button
              className={`button outlined download-report ${!isDateRangeSelected ? 'disabled' : ''}`}
              onClick={handleDownloadClick}>
              <DownloadSVG />
              &nbsp;{LABEL_CONSTANTS.DOWNLOAD}
            </button>
          )}
          {onToggleShowBulkTransactions != undefined && showBulkTransactions != undefined && (
            <ToggleSwitch
              label={TRANSACTION_CONSTANTS.BULK_TRANSACTIONS}
              checked={showBulkTransactions}
              onToggle={onToggleShowBulkTransactions}
            />
          )}
        </div>
      </div>

      <div className="button-row">
        <div className="filters">
          <div className="filter-category">
            <FilterDropdown
              placeholder={LABEL_CONSTANTS.TYPE}
              options={sortObjectsAlphabetically(typeOptions).filter(item => {
                return (
                  includeAdminFilters ||
                  ![
                    TransactionTypeEnum.CheckIn,
                    TransactionTypeEnum.CheckOut,
                    TransactionTypeEnum.Adjust,
                    TransactionTypeEnum.Move,
                    TransactionTypeEnum.Reconcile,
                  ].includes(item.value as TransactionTypeEnum)
                );
              })}
              value={{ name: type, value: type }}
              onChange={e => setType(e.value)}
            />
          </div>
          <div className="filter-category">
            <FilterDropdown
              placeholder={LABEL_CONSTANTS.STATUS}
              options={statusOptions}
              value={{ name: status, value: status }}
              onChange={e => setStatus(e.value)}
            />
          </div>
          
            {includeAdminFilters && (
              <div className="filter-category">
                <FilterDropdown
                  placeholder={LABEL_CONSTANTS.RESOLUTION}
                  options={resolutionOptions}
                  value={{ name: resolution, value: resolution }}
                  onChange={e => setResolution(e.value)}
                />
              </div>
            )}
          

          <div className="filter-category">
            <div className={`date-range-input ${showHighlight ? 'highlight' : ''}`}>
              <DateRangeInput
                value={dateRange}
                onChange={handleDateChange}
                isClearable
                rangeLimit={1}
                highlightPlaceholder={showHighlight}
              />
            </div>
            {showHighlight && (
              <div className="validated-input-message-error">
                <AlertSVG />
                {VALIDATION_ALERT_CONSTANTS.REQUIRED_FIELD}
              </div>
            )}
          </div>
        </div>
        
        {includeAdminFilters && (
          <button
            className="button outlined create-transaction"
            onClick={() => navigate(TRANSACTION_MANAGEMENT_PATHS.CREATE_CLIENT_TRANSACTION)}>
            <PlusCircleSvg />
            &nbsp;&nbsp;Create&nbsp;Client&nbsp;Transaction
          </button>
        )}
      </div>
    </div>
  );
};

export default FilterBy;
