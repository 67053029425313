import React from 'react';
import { FC, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';

type DateRangeInputProps = {
  disabled?: boolean;
  isClearable?: boolean;
  maxDate?: Date | null;
  minDate?: Date;
  onChange: (dates: { startDate: Date | null; endDate: Date | null }) => void;
  rangeLimit?: number;
  value: { startDate: Date | null; endDate: Date | null };
  highlightPlaceholder?: boolean;
};

const DateRangeInput: FC<DateRangeInputProps> = ({
  disabled = false,
  isClearable: clearButton = false,
  maxDate = new Date(),
  minDate,
  onChange,
  rangeLimit,
  value,
  highlightPlaceholder = false,
}: DateRangeInputProps) => {
  const [calculatedMaxDate, setCalculatedMaxDate] = useState<Date | null>(null);

  useEffect(() => {
    if (value.startDate && rangeLimit) {
      const maxAllowedDate = new Date(value.startDate);
      maxAllowedDate.setFullYear(maxAllowedDate.getFullYear() + rangeLimit);
      setCalculatedMaxDate(maxAllowedDate > new Date() ? new Date() : maxAllowedDate);
    } else {
      setCalculatedMaxDate(maxDate ?? new Date());
    }
  }, [value.startDate, rangeLimit]);

  const handleDateChange = (dates: [Date | null, Date | null]) => {
    const [startDate, endDate] = dates;
    const maxAllowedEndDate = calculatedMaxDate ?? maxDate;

    if (startDate && endDate) {
      if (maxAllowedEndDate && endDate > maxAllowedEndDate) {
        onChange({ startDate, endDate: maxAllowedEndDate });
      } else {
        onChange({ startDate, endDate });
      }
    } else {
      onChange({ startDate, endDate: null });
    }
  };

  const handleCalendarClose = () => {
    if (value.startDate && !value.endDate) {
      onChange({ startDate: null, endDate: null });
    }
  };

  const CustomInput = React.forwardRef<HTMLInputElement>((props, ref) => <input {...props} ref={ref} readOnly />);

  return (
    <div className="date-range-input">
      <DatePicker
        placeholderText="Select dates"
        selected={value.startDate}
        onChange={dates => handleDateChange(dates as [Date | null, Date | null])}
        startDate={value.startDate}
        endDate={value.endDate}
        selectsRange
        maxDate={calculatedMaxDate ?? maxDate}
        showYearDropdown
        showMonthDropdown
        minDate={minDate}
        isClearable={clearButton}
        disabled={disabled}
        dateFormat="MM/dd/yyyy"
        clearButtonClassName="date-range-input-clear-button"
        onCalendarClose={handleCalendarClose}
        customInput={<CustomInput />}
        className={highlightPlaceholder ? 'highlight-placeholder' : ''}
      />
    </div>
  );
};

export default DateRangeInput;
