import React, { FC, useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import BackButton from '../back-button/BackButton';
import ValidatedInput, { ValidatedState } from '../custom-input/ValidatedInput';
import FilterDropdown, { FilterOption } from '../custom-input/FilterDropdown';
import SortedTable from '../sorted-table/SortedTable';
import LinkedTransactions from './LinkedTransactions';
import AssociatedTransactions from './AssociatedTransactions';
import LinkedStorageMovements from './LinkedStorageMovements';
import Loader from '../loader/Loader';
import Modal from '../modal/Modal';
import TransactionLabelField from './TransactionLabelField';
import { TransactionFilterState } from './TransactionsList';
import { Account } from '../../types/interfaces/account.interfaces';
import { Animal } from '../../types/interfaces/animal.interfaces';
import { BulkTransaction, InventoryTrans, InventoryTransaction } from '../../types/interfaces/transaction.interfaces';
import { TransactionResolutionEnum, TransactionStatusEnum, TransactionTypeEnum } from '../../types/enums/transaction.enum';
import { Validators } from '../../types/enums/validators.enum';
import { getInventoryTransactionById, updateInventoryTransaction } from '../../api/inventoryTransactionsApi';
import { getBulkTransactionById, updateBulkInventoryTransaction } from '../../api/bulkTransactionApi';
import { getLinkedTransactionsById } from '../../api/transactionsApi';
import { getAccountById, getAccounts } from '../../api/accountApi';
import { getInventoryVarianceSummary, getSpecimenOwnerByAccountId } from '../../api/specimensApi';
import { showToast } from '../../services/toast.service';
import { getInventoryItemId } from '../../services/inventoryTransaction.service';
import { redirectToTopOfThePage } from '../../utils/commonUtils';
import { toastMessages } from '../../constants/errorMessages';
import { httpStatusCodes } from '../../constants/httpStatusCodes';
import {
  BUTTON_CONSTANTS,
  LABEL_CONSTANTS,
  PAGE_HEADER_CONSTANTS,
  TABLE_HEADER_CONSTANTS,
  TRANSACTION_CONSTANTS,
} from '../../constants/common';
import './transactionEdit.scss';

const TransactionEdit: FC<{ onChange: any; bulkOrder: boolean; filter: string; filterPreset: TransactionFilterState }> = ({
  onChange,
  bulkOrder,
  filter,
  filterPreset,
}): JSX.Element => {
  const { transactionId } = useParams();

  const [account, setAccount] = useState<Account>();
  const [animal, setAnimal] = useState<Animal>();
  const [associatedInventoryTrans, setAssociatedInventoryTrans] = useState<InventoryTransaction[]>([]);
  const [changedQuantity, setChangedQuantity] = useState<ValidatedState>();
  const [inventoryItem, setInventoryItem] = useState<FilterOption<string>>({ name: '', value: '' });
  const [inventoryTransaction, setInventoryTransaction] = useState<InventoryTrans>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isOpenReconcileModal, setIsOpenReconcileModal] = useState<boolean>(false);
  const [linkedTransactions, setLinkedTransactions] = useState<InventoryTransaction[]>([]);
  const [modalErrorContent, setModalErrorContent] = useState<JSX.Element>();
  const [notes, setNotes] = useState<string>();
  const [transactionLinkId, setTransactionLinkId] = useState<ValidatedState>();
  const [updatingTransaction, setUpdatingTransaction] = useState<boolean>(true);
  const [validate, setValidate] = useState<boolean>(false);

  const [status, setStatus] = useState<FilterOption<TransactionStatusEnum>>({ name: '', value: TransactionStatusEnum.Default });
  const [transactionType, setTransactionType] = useState<FilterOption<TransactionTypeEnum>>({
    name: '',
    value: TransactionTypeEnum.Default,
  });
  const [resolution, setResolution] = useState<FilterOption<TransactionResolutionEnum>>({
    name: '',
    value: TransactionResolutionEnum.Default,
  });

  const transactionTypeOptions = Object.keys(TransactionTypeEnum)
    .filter(type => type !== 'Default')
    .map(type => ({ name: `${type} Item`, value: type }));

  const statusOptions = Object.keys(TransactionStatusEnum)
    .filter(s => s !== 'Default')
    .map(s => ({ name: s, value: s }));

  const resolutionOptions = Object.keys(TransactionResolutionEnum)
    .filter(r => r !== 'Default')
    .map(r => ({ name: r, value: r }));

  useEffect(() => {
    redirectToTopOfThePage();
    getTransactions();
  }, []);

  const getLinkedTransactions = useCallback(async () => {
    try {
      const { data: transactions } = await getLinkedTransactionsById(+transactionId!);
      const ids = Array.from(new Set(transactions.map((t: InventoryTransaction) => t.accountId)));

      if (ids.length) {
        const response = await getAccounts(ids);
        setLinkedTransactions(
          transactions
            .filter((t: InventoryTransaction) => {
              return +transactionId! !== t.inventoryTransactionId;
            })
            .map(t => {
              return { ...t, account: (response.data as Account[]).find((a: Account) => a.accountId === t.accountId) };
            }),
        );
      }
    } catch {
      showToast.error(toastMessages.FAILED_TO_RETRIEVE_LINKED_TRANSACTIONS);
    }
  }, [transactionId]);

  useEffect(() => {
    getLinkedTransactions();
  }, [getLinkedTransactions]);

  const getTransactions = async () => {
    setIsLoading(true);

    try {
      const { data: trans } = bulkOrder
        ? await getBulkTransactionById(+transactionId!, { include: 'InventoryTransactions.Specimen.Animal,Account' })
        : await getInventoryTransactionById(+transactionId!, { include: 'Account,Specimen.Animal' });

      setInventoryTransaction(trans);
      setTransactionEditFormValues(trans);
    } catch {
      showToast.error(toastMessages.SOMETHING_WENT_WRONG);
    } finally {
      setIsLoading(false);
    }
  };

  const setTransactionEditFormValues = async (trans: InventoryTrans) => {
    try {
      setTransactionLinkId({ value: (trans?.transactionLinkId ?? '').toString(), valid: true });
      setTransactionType({ name: `${trans?.transactionType} Item`, value: trans?.transactionType });
      setAnimal(trans?.specimen?.animal);
      setInventoryItem({ name: getInventoryItemId(trans?.specimen), value: getInventoryItemId(trans?.specimen) });
      setChangedQuantity({ valid: true, value: trans?.changeQuantity?.toString()! });
      setStatus({ name: trans.status, value: trans.status });
      setResolution({ name: trans.resolution, value: trans.resolution });
      setNotes(trans.notes);

      if (bulkOrder && trans?.inventoryTransactions) {
        setAssociatedInventoryTrans(trans?.inventoryTransactions);
      }

      if (![TransactionTypeEnum.Reconcile, TransactionTypeEnum.Move].includes(trans.transactionType as TransactionTypeEnum)) {
        const { data: acc } = await getAccountById(trans.accountId);
        setAccount(acc);
      }
    } catch {
      showToast.error(toastMessages.SOMETHING_WENT_WRONG);
    }
  };

  const formIsValid = (): boolean => {
    if (changedQuantity?.value === '') {
      return false;
    } else if (transactionType.value != TransactionTypeEnum.Collect && transactionType.value != TransactionTypeEnum.Deposit) {
      if (bulkOrder && associatedInventoryTrans) {
        return !associatedInventoryTrans.some(item => Number(item.changeQuantity) === 0 || isNaN(Number(item.changeQuantity)));
      } else {
        return Number(changedQuantity?.value) === 0 || isNaN(Number(changedQuantity?.value)) ? false : true;
      }
    }
    return true;
  };

  const isQuantityValid = async (): Promise<boolean> => {
    const validateQuantity = async (specimenId: number, accountId: number, changedQty: number): Promise<boolean> => {
      const { data: specimenResponse } = await getSpecimenOwnerByAccountId(specimenId, accountId);
      const availableQuantity = specimenResponse[0]?.quantityAvailable ?? 0;

      if (Math.abs(changedQty) > availableQuantity) {
        showToast.error(
          `Transaction with specimenId ${specimenId}: Changed quantity (${changedQty}) exceeds available quantity (${availableQuantity}).`,
        );
        return false;
      }
      return true;
    };

    let allValid = true;

    if (bulkOrder && associatedInventoryTrans) {
      for (const transaction of associatedInventoryTrans) {
        if (transaction.specimenId && account?.accountId) {
          const isValid = await validateQuantity(transaction.specimenId, account.accountId!, Number(transaction.changeQuantity));
          if (!isValid) allValid = false;
        } else {
          allValid = false;
        }
      }
    } else if (inventoryTransaction?.specimenId && account?.accountId) {
      const isValid = await validateQuantity(inventoryTransaction.specimenId, account.accountId, Number(changedQuantity?.value));
      if (!isValid) allValid = false;
    }

    return allValid;
  };

  const onCancel = () => {
    setValidate(false);
    onChange();
    redirectToTopOfThePage();
  };

  const modifyInventoryTransactionObject = () => {
    let inventoryTransactionObject: InventoryTrans = JSON.parse(JSON.stringify(inventoryTransaction));

    inventoryTransactionObject.transactionType = transactionType.value;
    inventoryTransactionObject.status = status.value;
    inventoryTransactionObject.resolution = resolution.value;
    inventoryTransactionObject.notes = notes;

    if (bulkOrder) {
      inventoryTransactionObject.bulkTransactionLinkId = transactionLinkId?.value ? Number(transactionLinkId?.value) : undefined;

      const newAssociatedInventoryTrans = associatedInventoryTrans.map(item => {
        return { ...item, changeQuantity: Number(item?.changeQuantity) };
      });

      inventoryTransactionObject.inventoryTransactions = newAssociatedInventoryTrans;
    } else {
      inventoryTransactionObject.transactionLinkId = transactionLinkId?.value ? Number(transactionLinkId?.value) : undefined;
      inventoryTransactionObject.changeQuantity = Number(changedQuantity?.value);
    }

    return inventoryTransactionObject;
  };

  const updateTransaction = async (inventoryTransactionObject: InventoryTransaction) => {
    try {
      await updateInventoryTransaction(inventoryTransactionObject?.inventoryTransactionId!, inventoryTransactionObject);
      showToast.success(toastMessages.SUCCESSFULLY_UPDATED_TRANSACTION);

      if (
        inventoryTransactionObject.transactionType === TransactionTypeEnum.Sell ||
        inventoryTransactionObject.transactionType === TransactionTypeEnum.Purchase
      ) {
        await updateLinkedSellPurchaseTransaction(inventoryTransactionObject);
      }

      await checkForZeroVariance([inventoryTransactionObject]);
    } catch (error) {
      showToast.error(toastMessages.SOMETHING_WENT_WRONG);
    }
  };

  const updateBulkOrderTransaction = async (bulkTransactionObject: BulkTransaction) => {
    try {
      await updateBulkInventoryTransaction(bulkTransactionObject?.bulkOrderId!, bulkTransactionObject);
      showToast.success(toastMessages.SUCCESSFULLY_UPDATED_TRANSACTION);
      await checkForZeroVariance(bulkTransactionObject.inventoryTransactions!);
    } catch (error) {
      showToast.error(toastMessages.SOMETHING_WENT_WRONG);
    }
  };

  const updateLinkedSellPurchaseTransaction = async (inventoryTransactionObject: InventoryTransaction) => {
    try {
      let linkedInventoryTransactions: InventoryTransaction[] = JSON.parse(JSON.stringify(linkedTransactions));
      const isSellTransaction = inventoryTransactionObject.transactionType === TransactionTypeEnum.Sell;
      const associatedLinkedTransactionType = isSellTransaction ? TransactionTypeEnum.Purchase : TransactionTypeEnum.Sell;

      const linkedTransaction = linkedInventoryTransactions.find(
        transaction =>
          transaction.transactionType === associatedLinkedTransactionType &&
          ((isSellTransaction && transaction.transactionLinkId === inventoryTransactionObject.inventoryTransactionId) ||
            (!isSellTransaction && transaction.inventoryTransactionId === inventoryTransactionObject.transactionLinkId)),
      );

      if (linkedTransaction) {
        linkedTransaction.status = status.value;
        linkedTransaction.changeQuantity =
          associatedLinkedTransactionType === TransactionTypeEnum.Sell
            ? -Math.abs(Number(changedQuantity?.value))
            : Math.abs(Number(changedQuantity?.value));

        await updateInventoryTransaction(linkedTransaction?.inventoryTransactionId!, linkedTransaction);
        showToast.success(toastMessages.SUCCESSFULLY_UPDATED_TRANSACTION);
      }
    } catch (error) {
      showToast.error(toastMessages.SOMETHING_WENT_WRONG);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!updatingTransaction) return;
    setUpdatingTransaction(false);
    setValidate(true);

    if (formIsValid()) {
      setIsLoading(true);
      if (bulkOrder && inventoryTransaction?.bulkOrderId) {
        let bulkTransactionObject: BulkTransaction = modifyInventoryTransactionObject();
        await updateBulkOrderTransaction(bulkTransactionObject);
      } else {
        let inventoryTransactionObject: InventoryTransaction = modifyInventoryTransactionObject();
        await updateTransaction(inventoryTransactionObject);
      }
    } else {
      setUpdatingTransaction(true);
    }
    setIsLoading(false);
  };

  const filterDropdown = (dropdownLabel: string, dropdownValue: any, dropdownOptions: any, onChangeMethod: any) => {
    return (
      <div className="form-row">
        <label>{dropdownLabel}</label>
        <div className="input-container filter-dropdown-mobile">
          <FilterDropdown value={dropdownValue} options={dropdownOptions} onChange={onChangeMethod} />
        </div>
      </div>
    );
  };

  const onAnimalQtyChange = (e: any, index: number) => {
    let newQuantity = e?.target?.value
      .replace(/[^\d-]+/, '')
      .replace(/(\w)[-]/, '$1')
      .replace('--', '-');

    let newAssociatedInventoryTrans = [...associatedInventoryTrans];
    newAssociatedInventoryTrans[index].changeQuantity = newQuantity;
    setAssociatedInventoryTrans(newAssociatedInventoryTrans);
  };

  const checkForZeroVariance = async (inventoryTransactions: InventoryTransaction[]) => {
    try {
      const specimenIds = inventoryTransactions.map(tx => Number(tx.specimen!.specimenId)).filter(id => !isNaN(id));
      const varianceChecks = await Promise.all(specimenIds.map(specimenId => getInventoryVarianceSummary(specimenId)));

      const discrepancies = specimenIds.filter(id => {
        const varianceCheck = varianceChecks.find(v => v?.data?.specimenId === id);
        return varianceCheck && varianceCheck.data.zeroSumCheck != 0;
      });

      if (discrepancies.length > 0) {
        setIsOpenReconcileModal(true);
        setModalErrorContent(
          <div>
            The transaction update was successful. However, the inventory counts are incorrect for the following lot numbers:
            <b> {discrepancies.join(', ')}</b>. To resolve the issue, please ensure that the physical inventory counts match the
            customer ownership amounts. Note that these discrepancies might have existed prior to this transaction.
          </div>,
        );
      } else {
        handlePostUpdateActions();
      }
    } catch (err: any) {
      const error = err.response.data;
      if (error.status === httpStatusCodes[409]) {
        const detailObject = JSON.parse(error.detail.substring(error.detail.indexOf('{')));
        const errMsg = detailObject.ErrMsg;
      } else {
        showToast.error(toastMessages.SOMETHING_WENT_WRONG);
      }
      handlePostUpdateActions();
    }
  };

  const handlePostUpdateActions = () => {
    setValidate(false);
    setUpdatingTransaction(true);
    onChange();
    redirectToTopOfThePage();
  };

  return (
    <div className="transaction-edit">
      {isLoading ? (
        <Loader loaderSize={'small'} pageLoader />
      ) : (
        <div className="transaction-request">
          <Modal
            isOpen={isOpenReconcileModal}
            ignoreBackdrop
            onClose={() => {
              setIsOpenReconcileModal(false);
              handlePostUpdateActions();
            }}>
            <div className="sign-out-modal">
              <div className="header">
                <h4>{LABEL_CONSTANTS.VARIANCE_DETECTED}</h4>
              </div>
              <div className="body">
                <p className="modal-p-side-margin">{modalErrorContent}</p>
              </div>
              <div className="footer">
                <button
                  className="button green small"
                  onClick={() => {
                    setIsOpenReconcileModal(false);
                    handlePostUpdateActions();
                  }}>
                  {BUTTON_CONSTANTS.OK}
                </button>
              </div>
            </div>
          </Modal>
          <BackButton state={{ filterPreset, filter, bulkOrder }} />
          <div className="inventory-action card">
            <h1>{PAGE_HEADER_CONSTANTS.VIEW_EDIT_TRANSACTION}</h1>

            <form onSubmit={handleSubmit}>
              <h2>{TRANSACTION_CONSTANTS.TRANSACTION_INFORMATION}</h2>

              <TransactionLabelField
                label={bulkOrder ? `${LABEL_CONSTANTS.BULK_TRANSACTION_ID}:` : `${TRANSACTION_CONSTANTS.TRANSACTION_ID}:`}
                value={transactionId}
              />

              {bulkOrder ? (
                <TransactionLabelField label={`${LABEL_CONSTANTS.BULK_TRANSACTION_LINK_ID}:`} value={transactionLinkId?.value} />
              ) : (
                <div className="form-row">
                  <ValidatedInput
                    label={`${TRANSACTION_CONSTANTS.TRANSACTION_LINK_ID}:`}
                    type="number"
                    min={0}
                    validatedStateForAutoFill={transactionLinkId}
                    setValidatedState={setTransactionLinkId}
                    validators={[]}
                    validate={validate}
                  />
                </div>
              )}

              {bulkOrder ? (
                <TransactionLabelField
                  label={`${TRANSACTION_CONSTANTS.TRANSACTION_TYPE}:`}
                  value={inventoryTransaction?.transactionType}
                />
              ) : (
                filterDropdown(
                  `${TRANSACTION_CONSTANTS.TRANSACTION_TYPE}:`,
                  transactionType,
                  transactionTypeOptions,
                  setTransactionType,
                )
              )}

              <TransactionLabelField
                label={`${LABEL_CONSTANTS.ACCOUNT_OWNER_ID}:`}
                value={
                  [TransactionTypeEnum.Reconcile, TransactionTypeEnum.Move].includes(
                    inventoryTransaction?.transactionType as TransactionTypeEnum,
                  )
                    ? 'N/A'
                    : account?.accountId + '-' + account?.name
                }
              />

              {bulkOrder ? (
                <>
                  <div className="specimens-table">
                    <SortedTable
                      headers={[
                        { displayName: TABLE_HEADER_CONSTANTS.ANIMAL_NAME },
                        { displayName: TABLE_HEADER_CONSTANTS.INVENTORY_LOT_NUMBER },
                        { displayName: TABLE_HEADER_CONSTANTS.CHANGED_QUANTITY },
                      ]}
                      data={associatedInventoryTrans?.map((trans: InventoryTrans, index: number) => {
                        return [
                          {
                            content: trans?.specimen?.animal?.code
                              ? trans?.specimen?.animal?.code + ' - ' + trans?.specimen?.animal?.name
                              : '-',
                          },
                          { content: getInventoryItemId(trans?.specimen) },
                          {
                            content: (
                              <input
                                type="text"
                                value={trans?.changeQuantity}
                                onChange={e => onAnimalQtyChange(e, index)}
                                data-testid="change-quantity-input"
                              />
                            ),
                          },
                        ];
                      })}
                    />
                  </div>
                  <TransactionLabelField
                    label={`${LABEL_CONSTANTS.TOTAL_CHANGED_QUANTITY}:`}
                    value={inventoryTransaction?.totalQuantity?.toString()}
                  />
                </>
              ) : (
                <>
                  <TransactionLabelField
                    label={`${TABLE_HEADER_CONSTANTS.ANIMAL_NAME}:`}
                    value={animal?.code ? animal?.code + '-' + animal?.name : '-'}
                  />
                  <TransactionLabelField
                    label={`${TABLE_HEADER_CONSTANTS.INVENTORY_LOT_NUMBER}:`}
                    value={inventoryItem?.value ? inventoryItem?.value : '-'}
                  />

                  {transactionType.value === TransactionTypeEnum.Collect ||
                  transactionType.value === TransactionTypeEnum.Deposit ? (
                    <TransactionLabelField
                      label={`${TABLE_HEADER_CONSTANTS.CHANGED_QUANTITY}:`}
                      value={changedQuantity?.value ?? '-'}
                    />
                  ) : (
                    <div className="form-row">
                      <ValidatedInput
                        label={`${TABLE_HEADER_CONSTANTS.CHANGED_QUANTITY}:`}
                        type="number"
                        validatedStateForAutoFill={changedQuantity}
                        setValidatedState={setChangedQuantity}
                        validators={[Validators.REQUIRED]}
                        validate={validate}
                      />
                    </div>
                  )}
                </>
              )}

              {filterDropdown(`${LABEL_CONSTANTS.STATUS}:`, status, statusOptions, setStatus)}
              {filterDropdown(`${LABEL_CONSTANTS.RESOLUTION}:`, resolution, resolutionOptions, setResolution)}

              <div className="form-row expanded-text-area">
                <label htmlFor="inventory-notes-textarea">{LABEL_CONSTANTS.NOTES}:</label>
                <textarea
                  value={notes}
                  placeholder="Notes"
                  id="inventory-notes-textarea"
                  onChange={e => setNotes(e.target.value)}
                />
              </div>

              <TransactionLabelField
                label={`${TRANSACTION_CONSTANTS.TRANSACTION_DATE}:`}
                value={new Date(inventoryTransaction?.createdDatetime?.toString() as string).toLocaleDateString()}
              />
              <TransactionLabelField
                label={`${LABEL_CONSTANTS.LAST_UPDATED}:`}
                value={new Date(inventoryTransaction?.modifiedDatetime?.toString() as string).toLocaleDateString()}
              />
              <TransactionLabelField label={`${LABEL_CONSTANTS.LAST_UPDATED_BY}:`} value={inventoryTransaction?.modifiedBy} />

              {bulkOrder ? (
                <>
                  <h2>{TRANSACTION_CONSTANTS.ASSOCIATED_TRANSACTIONS}</h2>
                  <AssociatedTransactions associatedTransactions={inventoryTransaction?.inventoryTransactions} />
                </>
              ) : (
                <>
                  <h2>{TRANSACTION_CONSTANTS.LINKED_TRANSACTIONS}</h2>
                  <LinkedTransactions transactionId={+transactionId!} />
                </>
              )}

              <br />
              <h2>{TRANSACTION_CONSTANTS.LINKED_STORAGE_MOVEMENTS}</h2>
              <LinkedStorageMovements inventoryTransactions={bulkOrder ? associatedInventoryTrans : inventoryTransaction} />

              <div className="flex-right edit-transaction-buttons">
                <React.Fragment>
                  <button type="button" className="button small green inverted" onClick={onCancel}>
                    {BUTTON_CONSTANTS.CANCEL}
                  </button>
                  &ensp;
                </React.Fragment>

                <button type="submit" className="button small green" disabled={!formIsValid()}>
                  {BUTTON_CONSTANTS.SAVE}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default TransactionEdit;
