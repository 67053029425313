import { Dispatch, FC, SetStateAction, useState } from 'react';
import FilterDropdown, { FilterOption } from '../custom-input/FilterDropdown';
import DateRangeInput from '../custom-input/DateInputRange';
import Modal from '../modal/Modal';
import ClearFilterSVG from '../svgs/ClearFilterSvg.svg';
import { sortObjectsAlphabetically } from '../../utils/commonUtils';
import { TransactionResolutionEnum, TransactionStatusEnum, TransactionTypeEnum } from '../../types/enums';
import { TransactionFilterState } from '../transaction/TransactionsList';
import { BUTTON_CONSTANTS, LABEL_CONSTANTS } from '../../constants/common';
import './filterByModal.scss';

export type FilterByModalProps = {
  isOpen: boolean;
  onClose: () => void;
  filterState: TransactionFilterState;
  setFilterState: Dispatch<SetStateAction<TransactionFilterState>>;
  includeAdminFilters?: boolean;
  defaultFilterPreset: TransactionFilterState;
};

const FilterByModal: FC<FilterByModalProps> = ({
  isOpen,
  onClose,
  filterState,
  setFilterState,
  includeAdminFilters = false,
  defaultFilterPreset,
}) => {
  const [ModalFilterState, setModalFilterState] = useState<TransactionFilterState>(filterState);

  let typeOptions: FilterOption<string>[] = [];
  let statusOptions: FilterOption<string>[] = [];
  let resolutionOptions: FilterOption<string>[] = [];

  for (const [key, value] of Object.entries(TransactionTypeEnum)) {
    typeOptions.push({ name: key.replace('Default', 'All'), value: value });
  }

  for (const [key, value] of Object.entries(TransactionStatusEnum)) {
    statusOptions.push({ name: key.replace('Default', 'All'), value: value });
  }

  for (const [key, value] of Object.entries(TransactionResolutionEnum)) {
    resolutionOptions.push({ name: key.replace('Default', 'All').replace('Pickup', 'Picked Up'), value: value });
  }

  const handleApplyFilters = () => {
    setFilterState(ModalFilterState);
    onClose();
  };

  const handleResetFilters = () => {
    setModalFilterState(defaultFilterPreset);
  };

  return (
    <div className="filter-by-modal">
      <Modal isOpen={isOpen} onClose={onClose} ignoreBackdrop>
        <div className="modal-content">
          <h2 className="modal-header">{LABEL_CONSTANTS.FILTER_BY}</h2>
          <div className="reset-filters-button flex-right" onClick={() => handleResetFilters()}>
            <ClearFilterSVG />
            <label>{LABEL_CONSTANTS.RESET_FILTERS}</label>
          </div>
          <div className="modal-body">
            <div className="button-row">
              <div className="filters">
                <div className="filter">
                  <label htmlFor="filter-type">{LABEL_CONSTANTS.TYPE}: </label>
                  <FilterDropdown
                    placeholder={LABEL_CONSTANTS.TYPE}
                    options={sortObjectsAlphabetically(typeOptions).filter(item => {
                      return (
                        includeAdminFilters ||
                        ![
                          TransactionTypeEnum.CheckIn,
                          TransactionTypeEnum.CheckOut,
                          TransactionTypeEnum.Adjust,
                          TransactionTypeEnum.Move,
                          TransactionTypeEnum.Reconcile,
                        ].includes(item.value as TransactionTypeEnum)
                      );
                    })}
                    value={{ name: ModalFilterState.type, value: ModalFilterState.type }}
                    onChange={e => setModalFilterState({ ...ModalFilterState, type: e.value })}
                  />
                </div>

                <div className="filter">
                  <label htmlFor="filter-type">{LABEL_CONSTANTS.STATUS}: </label>
                  <FilterDropdown
                    placeholder={LABEL_CONSTANTS.STATUS}
                    options={statusOptions}
                    value={{ name: ModalFilterState.status, value: ModalFilterState.status }}
                    onChange={e => setModalFilterState({ ...ModalFilterState, status: e.value })}
                  />
                </div>

                {includeAdminFilters && (
                  <div className="filter">
                    <label htmlFor="filter-type">{LABEL_CONSTANTS.RESOLUTION}: </label>
                    <FilterDropdown
                      placeholder={LABEL_CONSTANTS.RESOLUTION}
                      options={resolutionOptions}
                      value={{ name: ModalFilterState.resolution, value: ModalFilterState.resolution }}
                      onChange={e => setModalFilterState({ ...ModalFilterState, resolution: e.value })}
                    />
                  </div>
                )}
                <div className="filter">
                  <label htmlFor="filter-type">{LABEL_CONSTANTS.DATE}: </label>
                  <div className="date-input">
                    <DateRangeInput
                      value={ModalFilterState.createdDatetime}
                      onChange={e => setModalFilterState({ ...ModalFilterState, createdDatetime: e, dateWasModified: true })}
                      isClearable
                      rangeLimit={1}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal-footer">
            <button onClick={onClose} className="button inverted medium green">
              {BUTTON_CONSTANTS.CANCEL}
            </button>
            <button className="button medium green" onClick={handleApplyFilters}>
              {BUTTON_CONSTANTS.APPLY}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default FilterByModal;
