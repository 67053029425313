export const convertToMilitary = (date: Date): string => {
  const hours = date.getHours().toLocaleString().padStart(2, '0');
  const minutes = date.getMinutes().toLocaleString().padStart(2, '0');
  const timeString = `${hours}:${minutes}`;
  const dateString = date.toLocaleDateString();

  return `${dateString} ${timeString}Z`;
};

export const dayAfterDate = (d: Date): Date => {
  let dayAfter = new Date(d);
  dayAfter.setDate(d.getDate() + 1);
  return dayAfter;
};

export const dateFilter = (dateWasSet: boolean, date: { startDate: Date | null; endDate: Date | null }): string => {
  const { startDate, endDate } = date;
  startDate?.setHours(0);
  endDate?.setHours(0);
  if (dateWasSet && startDate && endDate) {
    return `CreatedDatetime ge ${startDate?.toISOString()} and CreatedDatetime lt ${dayAfterDate(endDate).toISOString()}`;
  } else {
    return '';
  }
};
